import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { ReportsPage } from "../ReportsPage";
import { ListingInfo } from "./ListingInfo";
import { ListingComments } from "components/ListingComments";
import { useAppSelector } from "store/hooks";
import { getRouteByName } from "libs";

export const ListingPage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { isCommentsRead, isReportsRead, isPostsRead, isAll } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  useEffect(() => {
    if (!isPostsRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isPostsRead, isAll]);

  const items = (() => {
    const defaultItems = [
      {
        label: "Informatie generala",
        key: "#information",
        children: <ListingInfo />,
      },
    ];

    if (isAll || isCommentsRead) {
      defaultItems.push({
        label: "Comentarii",
        key: "#comments",
        children: <ListingComments />,
      });
    }

    if (isAll || isReportsRead) {
      defaultItems.push({
        label: "Raportari",
        key: "#reports",
        children: <ReportsPage filterKey="listing-reports" />,
      });
    }

    return defaultItems;
  })();

  return <Tabs items={items} defaultActiveKey={hash ? hash : "#information"} />;
};
