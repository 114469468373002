export interface MediaFile {
  id: number;
  mediaType: number;
  url: string;
}

export interface WithPagination<T> {
  pagination: {
    currentPage: number;
    lastPage: number;
    itemsPerPage: number;
    total: number;
  };
  data: T[];
}

export interface Permission {
  id: number;
  name: string;
}

export interface Country {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
}

export interface County {
  id: number;
  countryId: number;
  name: string;
  code: string;
  latitude: number;
  longitude: number;
}

export interface ServiceInterface {
  id: number;
  name: string;
  category: {
    id: number;
    name: string;
  }
}

export interface CategoryInterface {
  id: number;
  name: string;
  services: ServiceInterface[];
}

export interface NotificationInterface {
  id: string;
  data: {
    userId?: number;
    title: string;
    reportId?: number;
    post?: number
    created_at: string;
  };
  type: NotificationsTypes;
  title: string;
  readAt: string;
  createdAt: string;
}

export enum NotificationsTypes {
  NEW_CONTACT_FORM_SUBMITED = "new-contact-form-submited",
  NEW_POST_CREATED = "new-post-created",
  NEW_REPORT_CREATED = "new-report-created",
  NEW_USER_REGISTERED = "new-user-registered",
  UPCOMING_POST = "upcoming-post",
  USER_UPGRADE = "user-upgrade",
}
