export const localesRo = {
  PHYSICAL: "Fizica",
  JURIDICAL: "Juridica",
  PENDING: "In asteptare",
  ACTIVE: "Activ",
  INACTIVE: "Inactiv",
  BLOCKED: "Blocat",
  BLOCK: "Blocat",
  FREE: "Gratuit",
  PAID: "Platit",
  RESOLVED: "Rezolvat",
  aboutASecond: "acum o secunda",
  aboutAMinute: "acum un minut",
  minutes: "acum {{ count }} minutes",
  aboutAnHour: "acum o oră",
  hours: "acum {{count}} ore",
  aDay: "acum o zi",
  days: "acum {{count}} zile",
  aboutAMonth: "acum o lună",
  months: "acum {{count}} luni",
  aboutAYear: "acum aproximativ un an",
  years: "acum {{count}} ani"
};
